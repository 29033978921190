import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useHeader } from "../../providers/HeaderProvider";
import {
  NUMBER_OF_PLAYERS_PER_TEAM,
  NUMBER_OF_TEAMS_PER_DRAFT,
} from "../../consts";
import { updateMatch } from "../../api/matches";
import { useNavigate, useParams } from "react-router-dom";
import MatchEditor from "../../components/MatchEditor/MatchEditor";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { db } from "../../hooks/firebase";
import { Match } from "../../types";
import { usePlayers } from "../../providers/PlayersProvider";

const EditMatch = () => {
  const navigate = useNavigate();
  const { players } = usePlayers();
  const [isSaving, setIsSaving] = useState(false);
  const { setTitle } = useHeader();
  const { t } = useTranslation();

  const { id } = useParams();
  const [document, loadingMatch] = useDocument(doc(db, "matches", id || ""));

  const empty = !document;
  const shouldGenData = !empty && !loadingMatch;

  const match = useMemo<Match | undefined>(() => {
    if (shouldGenData) {
      return {
        ...document.data(),
      } as Match;
    }

    return undefined;
  }, [document, shouldGenData]);

  useEffect(() => {
    setTitle(t("EDIT_MATCH_DAY"));
  }, [t, setTitle]);

  const selectedPlayers = useMemo(() => {
    if (match) {
      const allPlayerIds = Object.values(match.teams).flat();
      return players?.filter((player) =>
        allPlayerIds.includes(player.documentId)
      );
    }

    return undefined;
  }, [match, players]);

  if (!id) {
    return null;
  }

  return (
    <MatchEditor
      edit
      loading={loadingMatch}
      saving={isSaving}
      initialValues={{
        settings: {
          date: match?.settings.date || undefined,
          time: match?.settings.time || undefined,
          location: match?.settings.location || undefined,
          numberOfTeams:
            match?.settings.numberOfTeams || NUMBER_OF_TEAMS_PER_DRAFT,
          numberOfPlayersPerTeam:
            match?.settings.numberOfPlayersPerTeam ||
            NUMBER_OF_PLAYERS_PER_TEAM,
        },
        selectedPlayers: selectedPlayers
          ? selectedPlayers.map((player) => ({
              label: player.name,
              value: player.documentId,
            }))
          : undefined,
        teams: match?.teams,
      }}
      onSubmit={async (values) => {
        setIsSaving(true);
        try {
          const res = await updateMatch({
            matchId: id,
            matchUpdates: values,
          });

          if (res.success && "data" in res) {
            navigate("/match/" + res.data!.matchId);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsSaving(false);
        }
      }}
    />
  );
};

export default EditMatch;
