import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useHeader } from "../../providers/HeaderProvider";
import {
  NUMBER_OF_PLAYERS_PER_TEAM,
  NUMBER_OF_TEAMS_PER_DRAFT,
} from "../../consts";
import { saveMatch } from "../../api/matches";
import { useUser } from "../../providers/UserProvider";
import { useNavigate } from "react-router-dom";
import MatchEditor from "../../components/MatchEditor/MatchEditor";
import { SelectedPlayers } from "../../types";
import { playersList } from "../../utils/test";

const defaultTime = new Date();
defaultTime.setHours(19, 0);

const initialValueTest = {
  settings: {
    date: new Date().toISOString(),
    time: defaultTime.toISOString(),
    location: "ראשון לציון",
    numberOfTeams: 3,
    numberOfPlayersPerTeam: 7,
  },
  selectedPlayers: playersList as SelectedPlayers,
  teams: undefined,
};

const initialValues = {
  settings: {
    date: new Date().toISOString(),
    time: defaultTime.toISOString(),
    location: "ראשון לציון",
    numberOfTeams: NUMBER_OF_TEAMS_PER_DRAFT,
    numberOfPlayersPerTeam: NUMBER_OF_PLAYERS_PER_TEAM,
  },
  selectedPlayers: undefined,
  teams: undefined,
};

const CreateMatch = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const { setTitle } = useHeader();
  const { user } = useUser();
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(t("CREATE_MATCH_DAY"));
  }, [t, setTitle]);

  return (
    <MatchEditor
      saving={isSaving}
      initialValues={
        user?.email?.includes("matanassa") ? initialValueTest : initialValues
      }
      onSubmit={async (values) => {
        setIsSaving(true);
        try {
          const res = await saveMatch({
            managerId: user!.uid,
            matchDraft: values,
          });

          if (res.success && "data" in res) {
            navigate("/match/" + res.data!.matchId);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsSaving(false);
        }
      }}
    />
  );
};

export default CreateMatch;
