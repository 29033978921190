export const playersList = [
  { label: "אביב א", value: "5SPBmLTKoQuGEpx3nPrO" },
  { label: "אדם ", value: "Yg5HUF4L71edl6YFqO9Y" },
  { label: "אופק", value: "SGo8j5h7CBHALypLMNGN" },
  { label: "אור ", value: "Y23piN2fUsZTf8jn4Djo" },
  { label: "אורי", value: "jslgfmL8IB5lpOYOegIF" },
  { label: "אושר", value: "l0jPZtJfp4WaCPOFxA4f" },
  { label: "אושר זוהר - מזמין חיצוני", value: "zGYNHNP2iS9fqYnBmCPf" },
  { label: "אלי", value: "mr42dMbgoU3hgf7PXCTV" },
  { label: "אפי", value: "FXFTGTQx15PBqUWpcigN" },
  { label: "אריאל", value: "Uodg71V0BKAUDESDBD2D" },
  { label: "בר א", value: "3u13BPOgGUPWGriRV7FS" },
  { label: "ג'ק", value: "rjfhSIfxcL9ZN6rXPNPJ" },
  { label: "גבננאו", value: "tJakv1ISCoUxFNQSdo2u" },
  { label: "גורביץ", value: "LzwZZG9Ju7avoZrzV8oA" },
  { label: "גלעד", value: "uRN4okTvBcVXNPxOZZ0F" },
  { label: "דני", value: "VdZGR6FONZjTeVww66we" },
  { label: "זיו", value: "esd9GvwP2JJfX61KCbfr" },
  { label: "סובי - מזמין חיצוני", value: "Pp3WmGDsGQel8VTkRtVK" },
  { label: "נח", value: "di4RbqsHkMPd13Q3QhlY" },
  { label: "לידן", value: "fduWvS7DjOHbMGm56TYW" },
  { label: "חי - מזמין", value: "v7e3p7iX0V9roNzR1lbo" },
];
