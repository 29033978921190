import { FC, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "../../providers/UserProvider";

type PrivateRouteProps = {
  children: ReactNode;
};

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const location = useLocation();
  const { user } = useUser();

  if (!user && location.pathname !== "/login") {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
