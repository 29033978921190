import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import {
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  defaultDropAnimationSideEffects,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { MatchDraft, Player } from "../../../../types";
import PlayerListItem from "../../../PlayersList/PlayerListItem";
import { Box, CircularProgress, Hidden } from "@mui/material";
import { useFormikContext } from "formik";
import PoolPlayers from "./PoolPlayers";
import QuickShuffle from "./QuickShuffle";
import {
  createDraft,
  createSortableDraft,
} from "../../../../utils/createDraft";
import SortablePlayersList from "./SortablePlayersList";
import { Team } from "../../../Team/Team";

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

type Containers = Record<UniqueIdentifier, UniqueIdentifier[]>;

type CreateTeamsProps = {
  players: Player[];
};

// make sure only number of teams are created
// make sure edit works
// make sure saving works
// create demo match and test match ending and entries

const CreateTeams: FC<CreateTeamsProps> = ({ players }) => {
  const { values, setFieldValue } = useFormikContext<MatchDraft>();
  const [items, setItems] = useState<Containers>(values.teams as Containers);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);

  const recentlyMovedToNewContainer = useRef(false);

  useEffect(() => {
    if (values.teams && !items) {
      setItems(values.teams);
    }

    if (!items && !values.teams && players) {
      setItems({
        pool: players.map((player) => player.documentId),
        ...Object.fromEntries(
          [...Array(values.settings?.numberOfTeams)].map((_, i) => [
            `team-${i + 1}`,
            [] as UniqueIdentifier[],
          ])
        ),
      });
    }
  }, [items, players, values]);

  useEffect(() => {
    if (items) {
      setFieldValue("teams", items);
    }
  }, [items, setFieldValue]);

  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items!) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items!
          ),
        });
      }

      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");

      if (overId != null) {
        if (overId in items!) {
          const containerItems = items![overId];

          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items]
  );
  const [clonedItems, setClonedItems] = useState<Containers | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const findContainer = (id: UniqueIdentifier) => {
    if (!items) return;

    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  };

  const onDragCancel = () => {
    if (clonedItems) {
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const getContainerPlayers = useCallback(
    (containerId: string) => {
      if (!items) return;

      const res = items[containerId]
        ?.map(
          (playerId) =>
            players.find((player) => player.documentId === playerId)!
        )
        .filter((player) => player)
        .sort((a, b) => {
          return (
            items[containerId].indexOf(a!.documentId) -
            items[containerId].indexOf(b!.documentId)
          );
        });

      return res;
    },
    [items, players]
  );

  const poolPlayers = useMemo(
    () => getContainerPlayers("pool"),
    [getContainerPlayers]
  );

  const onShuffle = useCallback(() => {
    setItems((items) => ({
      pool: [],
      ...createSortableDraft(
        createDraft(players, {
          numberOfTeams: values.settings?.numberOfTeams!,
          numberOfPlayersPerTeam: values.settings?.numberOfPlayersPerTeam!,
        })
      ),
    }));
  }, [players, values.settings]);

  const onReset = useCallback(() => {
    setItems({
      pool: players.map((player) => player.documentId),
      ...Object.fromEntries(
        [...Array(values.settings?.numberOfTeams)].map((_, i) => [
          `team-${i + 1}`,
          [] as UniqueIdentifier[],
        ])
      ),
    });
  }, [players, values]);

  if (!items) {
    return <CircularProgress />;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(items);
      }}
      onDragOver={({ active, over }) => {
        if (!items) return;

        const overId = over?.id;

        if (overId == null || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItems((items) => {
            const activeItems = items![activeContainer];
            const overItems = items![overContainer];
            const overIndex = overItems.indexOf(overId);
            const activeIndex = activeItems.indexOf(active.id);

            let newIndex: number;

            if (overId in items!) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                  over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex =
                overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: items![activeContainer].filter(
                (item) => item !== active.id
              ),
              [overContainer]: [
                ...items![overContainer].slice(0, newIndex),
                items![activeContainer][activeIndex],
                ...items![overContainer].slice(
                  newIndex,
                  items![overContainer].length
                ),
              ],
            };
          });
        }
      }}
      onDragEnd={({ active, over }) => {
        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
          const activeIndex = items[activeContainer].indexOf(active.id);
          const overIndex = items[overContainer].indexOf(overId);

          if (activeIndex !== overIndex) {
            setItems((ci) => ({
              ...ci,
              [overContainer]: arrayMove(
                ci![overContainer],
                activeIndex,
                overIndex
              ),
            }));
          }
        }

        setActiveId(null);
      }}
      onDragCancel={onDragCancel}
    >
      <Hidden smUp>
        <QuickShuffle onClick={onShuffle} />
      </Hidden>
      <>
        <Box display="flex" flex={1}>
          <PoolPlayers
            items={items.pool}
            players={poolPlayers}
            onShuffle={onShuffle}
            onReset={onReset}
          />
          <Box display="flex" flex={1}>
            {Object.keys(items).map((containerId) =>
              containerId === "pool" ? null : (
                <Team
                  key={containerId}
                  containerId={containerId}
                  players={getContainerPlayers(containerId)}
                >
                  <SortablePlayersList
                    items={items[containerId]}
                    players={getContainerPlayers(containerId) || []}
                    id={containerId}
                  />
                </Team>
              )
            )}
          </Box>
        </Box>
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimation}
            style={{ zIndex: 99999999 }}
          >
            {activeId ? (
              <PlayerListItem
                isOverlay
                {...(players.find((p) => p.documentId === activeId) as Player)}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </>
    </DndContext>
  );
};

export default CreateTeams;
