import React, { useMemo } from "react";
import { usePlayers } from "../../providers/PlayersProvider";
import PlayersList from "../../components/PlayersList/PlayersList";
import CustomCard from "../../components/CustomCard/CustomCard";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ProblemMakers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { players, loading } = usePlayers();

  const playersThatDontHaveEntries = useMemo(
    () =>
      loading
        ? []
        : players
            ?.filter((player) => player.entries <= 0 && !player.temporary)
            .sort((a, b) => a.entries - b.entries) || [],
    [players, loading]
  );

  return (
    <CustomCard divider={false} title={t("ENTRIES_DEBT")}>
      <Box overflow={"auto"}>
        <PlayersList
          compact
          showRank={false}
          showEntries
          players={playersThatDontHaveEntries}
          loading={loading}
          onPlayerClick={(player) => {
            navigate(`/players/edit/${player.documentId}`);
          }}
        />
      </Box>
    </CustomCard>
  );
};

export default ProblemMakers;
