import { UniqueIdentifier } from "@dnd-kit/core";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { Player } from "../../../../types";
import SortablePlayersList from "./SortablePlayersList";
import { Panel } from "../../../Panel/Panel";
import { useTranslation } from "react-i18next";
import { Restore, Shuffle } from "@mui/icons-material";

type PoolPlayersProps = {
  items: UniqueIdentifier[];
  players?: Player[];
  onShuffle?: () => void;
  onReset?: () => void;
};

const PoolPlayers: FC<PoolPlayersProps> = ({
  items,
  players,
  onShuffle,
  onReset,
}) => {
  const { t } = useTranslation();
  return (
    <Panel permanent title={t("ASSIGN_PLAYERS")}>
      <Box
        sx={{
          overflowY: "auto",
          scrollbarWidth: "none",
          flex: 1,
          p: 1,
          pb: 0,
        }}
      >
        <SortablePlayersList id={"pool"} items={items} players={players} />
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              {t("ACTIONS")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="inherit"
              variant="contained"
              startIcon={<Restore />}
              onClick={onReset}
              fullWidth
            >
              {t("RESET_TEAMS")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              startIcon={<Shuffle />}
              onClick={onShuffle}
              fullWidth
            >
              {t("QUICK_SHUFFLE")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Panel>
  );
};

export default PoolPlayers;
